import { Box, Flex, Stack } from '@chakra-ui/react';
import TextLink from '@components/ra/interactive/TextLink';
import { Routes } from '@constants/routes';
import { useRouter } from 'next/router';
import CallUsButton from '../CallUsButton';

const CallToAction = ({ ...rest }) => {
  const router = useRouter();

  return (
    <Stack spacing={4} alignItems="center" w="100%" {...rest}>
      <Flex width={['100%', '100%', '100%', '300px']}>
        <CallUsButton />
      </Flex>
      {router.pathname === Routes.index && (
        <Box>
          <TextLink href={Routes.guideIntroduction} text="Or Shop Online for Plans" />
        </Box>
      )}
    </Stack>
  );
};

export default CallToAction;
