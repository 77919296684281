import { Box, Flex, Grid, Stack } from '@chakra-ui/react';
import Body1 from '@components/ra/typography/Body1';
import Header2 from '@components/ra/typography/Header2';
import { antlerStyle, defaultTransition } from '@constants/styles';
import openInNewIcon from '@public/ra/icons/control/external-arrows.svg';
import axios from '@public/ra/media-logos/axios.svg';
import fierce from '@public/ra/media-logos/fierce.svg';
import forbes from '@public/ra/media-logos/forbes.svg';
import sanDiegoTribune from '@public/ra/media-logos/san-diego-tribune.svg';
import { FsmColors } from 'chakra-ui/fsmTheme';
import Image from 'next/image';
import Link from 'next/link';

const newsData = [
  {
    link: 'https://www.axios.com/pro/health-tech-deals/2022/08/25/fair-square-medicare-raises-15-million-navigation',
    quote: 'Exclusive: Fair Square earns $15MM to help seniors navigate Medicare.',
    color: FsmColors.mint,
    image: axios,
    alt: 'Axios Logo',
  },
  {
    image: forbes,
    quote:
      'Fair Square Medicare Just Raised Its Series A. Here’s How It And Other Digital Health Platforms Are Guiding Seniors Through The U.S. Healthcare Maze',
    link:
      'https://www.forbes.com/sites/sethjoseph/2022/08/25/fair-square-medicare-just-raised-its-series-a-heres-how-it-and-other-digital-health-platforms-are-guiding-seniors-through-the-us-healthcare-maze/?sh=4f893e1b6c80',
    color: FsmColors.red,
    alt: 'Forbes Logo',
  },
  {
    image: sanDiegoTribune,
    quote: 'San Diego’s Fair Square Medicare snags $15M to help seniors navigate insurance market',
    link:
      'https://www.sandiegouniontribune.com/business/story/2022-08-25/san-diegos-fair-square-medicare-snags-15m-to-help-seniors-navigate-insurance-market',
    color: FsmColors.blue,
    alt: 'San Diego Tribune Logo',
  },
  {
    image: fierce,
    quote: 'Fair Square Medicare secures $15M to build out digital care navigator for seniors',
    link:
      'https://www.fiercehealthcare.com/health-tech/fair-square-medicare-secures-15m-build-out-digital-care-navigator-seniors',
    color: FsmColors.teal,
    alt: 'Fierce Logo',
  },
];

const News = () => {
  return (
    <Box pt={['80px', '80px', '120px', 160]} px={antlerStyle.px} bg="tan">
      <Stack maxW="1400px" mx="auto" spacing="60px">
        <Header2>We're in the news!</Header2>
        <Grid
          templateColumns={['1fr', '1fr', '1fr 1fr', 'repeat(4, 1fr)']}
          gap={4}
          overflowX="scroll"
          justifyContent={['center', 'center', 'center', 'start']}
          sx={{
            '::-webkit-scrollbar': {
              display: 'none',
            },
          }}
        >
          {newsData.map(({ link, image, quote, color, alt }, index) => (
            <Flex key={link} justifyContent="center" bg="white">
              <Link href={link} key={link} target="_blank">
                <Box
                  key={index}
                  position="relative"
                  h={['auto', 'auto', 386]}
                  w={[300]}
                  border="10px solid"
                  borderColor={color}
                  p={8}
                  overflow="hidden"
                  transition={defaultTransition}
                  _hover={{
                    transform: 'scale(.98)',
                  }}
                >
                  <Box position="relative" height="40px" overflow="hidden" objectFit="contain">
                    <Image height={30} src={image} alt={alt} />
                  </Box>
                  <Box pt={4} pb={[9, 9, 0]} overflow="hidden" textOverflow="ellipsis">
                    <Body1>{quote}</Body1>
                  </Box>
                  <Box position="absolute" right={0} bottom={0}>
                    <Image src={openInNewIcon} width={44} height={44} alt="Open In New Tab Icon" />
                  </Box>
                </Box>
              </Link>
            </Flex>
          ))}
        </Grid>
      </Stack>
    </Box>
  );
};

export default News;
