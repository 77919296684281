import { AspectRatio, Box, Divider, Flex, Stack } from '@chakra-ui/react';
import Body2 from '@components/ra/typography/Body2';
import Eyebrow from '@components/ra/typography/Eyebrow';
import Image from 'next/image';

const MainPhoto = ({ imageSrc, clientFirstName, bulletPoints }) => {
  const renderBulletPoints = () => (
    <Stack
      position={['relative', 'relative', 'relative']}
      bottom={['12px', '12px', '16px']}
      left={['12px', '12px', '16px']}
      bg="white"
      py={4}
      divider={<Divider />}
    >
      {bulletPoints.map((bulletPoint, index) => (
        <Flex px={4}>{index === 0 ? <Eyebrow>{bulletPoint}</Eyebrow> : <Body2>{bulletPoint}</Body2>}</Flex>
      ))}
    </Stack>
  );

  return (
    <Box position="relative" maxW="100%">
      <AspectRatio ratio={1} width="100%" height="100%" overflow="hidden" position="relative" objectFit="cover">
        <Image width={500} height={500} src={imageSrc} alt={`${clientFirstName}'s photo.`} priority />
      </AspectRatio>
    </Box>
  );
};

export default MainPhoto;
