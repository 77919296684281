import { Box, Flex } from '@chakra-ui/react';
import Body1 from '@components/ra/typography/Body1';
import Body2 from '@components/ra/typography/Body2';
import { FsmColors } from 'chakra-ui/fsmTheme';
import { titleCase } from 'title-case';

const colors = [FsmColors.mint, FsmColors.teal, FsmColors.navy, FsmColors.red, FsmColors.tan];

interface Props {
  review: any;
  index: number;
  color?: FsmColors; // color for the card's background
  standalone?: boolean; // this card will render alone (not in a carousel)
}

const Card = (props: Props) => {
  const { color, index, review, standalone } = props;
  const { first_name, comment, response_received_at } = review;
  const date = new Date(response_received_at);

  const isDarkBg = index % colors.length === 2;

  return comment && first_name && response_received_at ? (
    <Box
      alignSelf={standalone ? 'center' : 'auto'}
      textAlign="left"
      w={standalone ? ['100%', '100%', 600] : 350}
      h={standalone ? 'auto' : [330, 330, 362]}
      mt={standalone ? 0 : 68}
      bg={color || colors[index % colors.length]}
      display="flex"
      flexDir="column"
    >
      <Flex w="100%">
        <Body1 bold bg="white" p={15} mr="auto">
          10/10
        </Body1>
      </Flex>
      <Body1 pt={'24px'} px={'30px'} color={isDarkBg ? 'white' : 'black'} noOfLines={!standalone && [5]}>
        {comment}
      </Body1>
      <Flex flexDir="column" w="100%" p="15px 30px 30px 30px" mt="auto">
        <Body1 ml="auto" color={isDarkBg ? 'white' : 'black'} bold>
          {titleCase(first_name.toLowerCase())}
        </Body1>
        <Body2 ml="auto" color={isDarkBg ? 'white' : 'black'}>
          {`${date.getUTCMonth() + 1}/${date.getDate()}/${date.getUTCFullYear()}`}
        </Body2>
      </Flex>
    </Box>
  ) : null;
};

export default Card;
