import {
  Accordion as ChakraAccordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
} from '@chakra-ui/react';
import Body1 from '@components/ra/typography/Body1';
import Header3 from '@components/ra/typography/Header3';
import minusIcon from '@public/ra/icons/control/minus.svg';
import expandIcon from '@public/ra/icons/control/plus.svg';
import Image from 'next/image';
import { useState } from 'react';

const Accordion = ({ data, width = '100%', maxWidth = '1000px' }) => {
  const [selectedItem, setSelectedItem] = useState<number>(null);
  return (
    <ChakraAccordion width={width} maxW={maxWidth} allowToggle>
      {data.map(({ header, description }, index) => (
        <AccordionItem key={header}>
          <AccordionButton
            py={8}
            onClick={() => setSelectedItem((prev) => (prev === index ? null : index))}
            textAlign="left"
            gap={4}
          >
            <Flex w="100%" justifyContent="space-between">
              <Header3>{header}</Header3>
            </Flex>
            <Box>
              <Image
                src={selectedItem === index ? minusIcon : expandIcon}
                alt={selectedItem === index ? 'Minus Icon' : 'Expand Icon'}
              />
            </Box>
          </AccordionButton>
          <AccordionPanel maxW="800px">
            <Body1>{description}</Body1>
          </AccordionPanel>
        </AccordionItem>
      ))}
    </ChakraAccordion>
  );
};

export default Accordion;
