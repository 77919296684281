import { Box, Flex, keyframes, Skeleton } from '@chakra-ui/react';
import Header3 from '@components/ra/typography/Header3';
import { antlerStyle } from '@constants/styles';
import bbbLogo from '@public/FSM-BBB-Logo.svg';
import { numberWithCommas } from '@utils/formatNumbers';
import Image from 'next/image';
import Card from './Card';

const bannermove = keyframes`
  0% {
    margin-left: 0px;
  }
  100% {
    margin-left: -2125px;
  }
`;

const BaseReviews = ({ data, loading, header = null }) => {
  const customerSurvey = !data?.findDisplayReviews?.reviews?.length
    ? [1, 2, 3, 4, 5, 6]
    : data.findDisplayReviews.reviews;

  const animation = `${bannermove} 110s linear infinite`;

  if (!data || !data.findDisplayReviews.reviews) return null;

  const formattedReviewCount = numberWithCommas(data?.findDisplayReviews?.count);

  const headerToShow =
    header ?? `Based on ${formattedReviewCount} reviews, 96% of customers would recommend us to a friend.`;

  return (
    <Flex pt={['60px', '60px', '60px', 160]} bg="tan" flexWrap="wrap" overflow="hidden">
      <Box w={['100%', '100%', '66%']} pl={antlerStyle.px}>
        <Header3>{headerToShow}</Header3>
      </Box>
      <Flex
        order={[3, 3, 3, 2]}
        pr={[0, 0, 0, antlerStyle.desktopPx]}
        pt={[8, 8, 8, 0]}
        w={['100%', '100%', '100%', '34%']}
        minW={['200px', '240px', '300px']}
        height="100px"
        position="relative"
        objectFit="cover"
        mt={[4, 4, 8, 0]}
        justifyContent={['center', 'center', 'center', 'flex-end']}
      >
        <Image width={240} src={bbbLogo} alt="Better Business Bureau logo." />
      </Flex>
      <Flex w="100%" order={[2, 2, 2, 3]} animation={animation} h={418} mt={[0, 0, 0, 16]}>
        {customerSurvey.map((review, i) => (
          <Skeleton isLoaded={!loading} startColor="gray.100" endColor="gray.200" w={350} h={180} rounded="lg" key={i}>
            <Card review={review} index={i} />
          </Skeleton>
        ))}
      </Flex>
    </Flex>
  );
};

export default BaseReviews;
