import { gql } from '@apollo/client';

const GET_REVIEWS = gql`
  query($agentName: String) {
    findDisplayReviews(agentName: $agentName) {
      count
      reviews {
        first_name
        comment
        response_received_at
      }
      clientCount
    }
  }
`;

export default GET_REVIEWS;
