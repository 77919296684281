import { Box, Flex, Stack } from '@chakra-ui/react';
import { antlerStyle } from '@constants/styles';
import { FsmColors } from 'chakra-ui/fsmTheme';
import { StaticImageData } from 'next/image';
import Body1 from '../typography/Body1';
import Eyebrow from '../typography/Eyebrow';
import Header3 from '../typography/Header3';

type StepData = {
  alt?: string;
  bg: FsmColors;
  description: string;
  header: string;
  image?: StaticImageData;
};

type Props = {
  data: StepData[];
  renderImage: (image: StaticImageData, alt: string) => React.ReactNode;
};

const Steps = ({ data, renderImage }: Props) => {
  return (
    <Stack spacing={0} w="100%">
      {data.map(({ alt, bg, description, header, image }, index) => (
        <Flex
          key={alt}
          flexDir={['column', 'column', 'column', 'row']}
          justifyContent="center"
          alignItems="center"
          gap={[10, 10, 12, '120px']}
          py="60px"
          px={antlerStyle.px}
          bg={bg}
        >
          <Flex justifyContent="center" width={['100%', '100%', '400px']}>
            {renderImage(image, alt)}
          </Flex>
          <Stack
            maxW={['100%', '100%', '650px']}
            spacing={4}
            pr={['0px', '0px', '0px', '60px']}
            color={bg === FsmColors.navy ? 'white' : 'black'}
          >
            <Box>
              <Eyebrow>Step {index + 1}</Eyebrow>
            </Box>
            <Box>
              <Header3 color={bg === FsmColors.navy ? 'white' : 'black'}>{header}</Header3>
            </Box>
            <Box>
              <Body1>{description}</Body1>
            </Box>
          </Stack>
        </Flex>
      ))}
    </Stack>
  );
};

export default Steps;
