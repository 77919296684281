import { Box, Highlight as ChakraHighlight, Tooltip, useOutsideClick } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';

const Query = ({ query: { key, description, indexInString }, renderText }) => {
  const [tooltipOpen, setTooltipOpen] = useState(null);

  const highlightContainerRef: any = useRef();
  const [fontSize, setFontSize] = useState(null);

  useOutsideClick({
    ref: highlightContainerRef,
    handler: () => setTooltipOpen(null),
  });

  useEffect(() => {
    if (highlightContainerRef && highlightContainerRef.current) {
      setFontSize(window.getComputedStyle(highlightContainerRef.current.parentElement, null).fontSize.substring(0, 2));
    }
  }, []);

  return (
    <Tooltip
      label={description}
      isOpen={tooltipOpen === indexInString}
      padding={2}
      bg="highlight"
      color="black"
      borderRadius={12}
      border="1px solid black"
      fontSize={[16, 16, 16, 18]}
    >
      <Box
        ref={highlightContainerRef}
        onClick={() => setTooltipOpen((prev) => (prev === indexInString ? null : indexInString))}
        cursor="pointer"
        display="inline"
      >
        {renderText(
          <ChakraHighlight
            query={key}
            styles={{
              py: `${fontSize / 3.4}px`,
              px: `${fontSize / 1.4}px`,
              bg: 'highlight',
              rounded: fontSize / 1.2,
            }}
          >
            {' ' + key + ' '}
          </ChakraHighlight>
        )}
      </Box>
    </Tooltip>
  );
};

export default Query;
