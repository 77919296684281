import { Box, Flex, Stack } from '@chakra-ui/react';
import { Routes } from '@constants/routes';
import CallUsButton from '@components/ra/common/CallUsButton';
import Body1 from '@components/ra/typography/Body1';
import Body2 from '@components/ra/typography/Body2';
import Eyebrow from '@components/ra/typography/Eyebrow';
import Quote from '@components/ra/typography/Quote';
import { StaticImageData } from 'next/image';
import { useState } from 'react';
import Avatars from './Avatars';

type QuoteSliderData = {
  agentVariant: boolean;
  eyebrow: string;
  quotes: {
    name: string;
    quote: string;
    image: StaticImageData;
    year?: number;
    bulletPoints?: string[];
  }[];
  renderSelectedImage: ({
    imageSrc,
    alt,
    bulletPoints = null,
  }: {
    imageSrc: StaticImageData;
    alt: string;
    bulletPoints?: string[];
  }) => React.ReactNode;
  quoteHeight: any;
};

const QuoteSlider = ({ agentVariant, quotes, eyebrow, renderSelectedImage, quoteHeight = null }: QuoteSliderData) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const agentSlug = quotes[selectedIndex].name.toLowerCase().replace(' ', '-');

  return (
    <Flex
      flexDir={['column', 'column', 'column', 'row']}
      pt={['72px', '72px', '72px', 160]}
      alignItems={['center', 'center', 'center', 'stretch']}
      w="100%"
      gap={[0, 0, 0, '80px']}
    >
      <Eyebrow display={['auto', 'auto', 'auto', 'none']}>{eyebrow}</Eyebrow>
      <Box pt={[8, 8, 8, 0]}>
        {renderSelectedImage({
          imageSrc: quotes[selectedIndex].image,
          alt: `${quotes[selectedIndex].name} Headshot`,
          bulletPoints: quotes[selectedIndex].bulletPoints,
        })}
      </Box>
      <Flex w="100%" flexDir="column" flex={1} overflow="hidden">
        <Stack spacing={0} flex={1}>
          <Eyebrow display={['none', 'none', 'none', 'block']}>{eyebrow}</Eyebrow>
          <Flex flexDir="column" pt={[8, 8, 8, 10]} minHeight={quoteHeight}>
            <Quote>“{quotes[selectedIndex].quote}”</Quote>
            <Body1 pt={2}>{quotes[selectedIndex].name}</Body1>
            {quotes[selectedIndex].year && <Body2>{quotes[selectedIndex].year}</Body2>}
          </Flex>

          {agentVariant && (
            <CallUsButton mt={3} text={`Meet ${quotes[selectedIndex].name}`} href={`${Routes.agent}/${agentSlug}`} />
          )}
        </Stack>
        <Box w="100%" mt={[4, 4, 4, 'auto']}>
          <Avatars quotes={quotes} selectedIndex={selectedIndex} setSelectedIndex={setSelectedIndex} />
        </Box>
      </Flex>
    </Flex>
  );
};

export default QuoteSlider;
