import { Heading } from '@chakra-ui/react';

const Stat = ({ children, ...rest }) => {
  return (
    <Heading fontSize={[110, 120]} {...rest}>
      {children}
    </Heading>
  );
};

export default Stat;
