import { Box } from '@chakra-ui/react';
import Query from './Query';

const Highlight = ({ text, queries, renderText = null, ...rest }) => {
  const renderQuery = (query) => <Query key={query.key} query={query} renderText={renderText} />;
  const renderTextWrapper = (text) => <div key={text}>{renderText(text)}</div>;

  const queriesWithIndicies = queries
    .map((query) => {
      return {
        ...query,
        indexInString: text.indexOf(query.key),
      };
    })
    .sort((a, b) => a.indexInString - b.indexInString);

  let encodedString = text;

  for (const query of queriesWithIndicies) {
    encodedString = encodedString.replace(query.key, '$$$$');
  }

  const textBlocks = encodedString.split('$$').filter((text) => !!text);

  const componentList: any[] = [];

  let textInd = 0,
    queryInd = 0;

  if (queriesWithIndicies[0].indexInString === 0) {
    componentList.push(renderQuery(queriesWithIndicies[0]));
    queryInd++;
  }

  while (textInd < textBlocks.length && queryInd < queriesWithIndicies.length) {
    componentList.push(renderTextWrapper(textBlocks[textInd]));
    componentList.push(renderQuery(queriesWithIndicies[queryInd]));
    textInd++;
    queryInd++;
  }

  if (textInd < textBlocks.length) componentList.push(renderTextWrapper(textBlocks[textInd]));
  if (queryInd < queriesWithIndicies.length) componentList.push(renderQuery(queriesWithIndicies[queryInd]));

  return (
    <Box sx={{ ' *': { display: 'inline' } }} {...rest}>
      {componentList}
    </Box>
  );
};

export default Highlight;
