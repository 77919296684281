import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import { AspectRatio, Flex } from '@chakra-ui/react';
import Button from '@components/ra/interactive/Button';
import { useIsMobile } from '@hooks/useIsMobile';
import Image from 'next/image';
import { useEffect, useRef } from 'react';

const border = ['4px solid', '4px solid', '6px solid'];
const defaultBorderColor = 'transparent !important';
const selectedBorderColor = 'red.400 !important';
const avatarWidth = ['40px', '48px'];

const Avatars = ({ quotes, selectedIndex, setSelectedIndex }) => {
  const isMobile = useIsMobile();

  const rootRef = useRef(null);
  const selectedAvatarRef = useRef(null);

  const renderAvatar = (quote, index) => {
    const isSelected = index === selectedIndex;

    return (
      <AspectRatio
        key={quote.name}
        ref={isSelected ? selectedAvatarRef : null}
        minW={avatarWidth}
        maxW={avatarWidth}
        h={avatarWidth}
        outline={border}
        outlineColor={isSelected ? selectedBorderColor : defaultBorderColor}
        transition="outline 0.2s ease-in-out"
        cursor="pointer"
        onClick={() => setSelectedIndex(index)}
        ratio={1}
        objectFit="cover"
      >
        <Image width={100} src={quote.image} alt={`${quote.name} Headshot`} />
      </AspectRatio>
    );
  };

  const renderAvatars = () => {
    return quotes.map((quote, index) => {
      return renderAvatar(quote, index);
    });
  };

  useEffect(() => {
    if (selectedAvatarRef) {
      const avatarParentOffset = selectedAvatarRef.current.offsetLeft - rootRef.current.offsetLeft;
      rootRef.current.scrollLeft = isMobile ? avatarParentOffset - 64 : selectedAvatarRef.current.offsetLeft - 84;
    }
  }, [selectedIndex]);

  return (
    <Flex alignItems="center" w="100%" position="relative">
      <Button
        borderRadius="none !important"
        onClick={() => {
          setSelectedIndex((prev) => (prev === 0 ? quotes.length - 1 : prev - 1));
        }}
        position="absolute"
        left={0}
        zIndex={1}
        p={0}
        paddingX={0}
        width={['48px', '60px']}
        height={['48px', '60px']}
      >
        <ArrowBackIcon width={6} height={6} />
      </Button>
      <Flex
        ref={rootRef}
        flex={1}
        overflowX="scroll"
        gap={[4, 5]}
        py={2}
        px={['64px', '64px', '76px']}
        alignItems="center"
        scrollBehavior="smooth"
        sx={{
          '::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        {renderAvatars()}
      </Flex>
      <Button
        borderRadius="none !important"
        onClick={() => {
          setSelectedIndex((prev) => (prev === quotes.length - 1 ? 0 : prev + 1));
        }}
        position="absolute"
        right={0}
        p={0}
        paddingX={0}
        width={['48px', '56px']}
        height={['48px', '56px']}
      >
        <ArrowForwardIcon width={6} height={6} />
      </Button>
    </Flex>
  );
};

export default Avatars;
