import { AspectRatio, Box, Flex, Stack } from '@chakra-ui/react';
import MainPhoto from '@components/ra/common/landingPages/BaseClients/MainPhoto';
import QuoteSlider from '@components/ra/pages/index/fsm/common/QuoteSlider';
import Body1 from '@components/ra/typography/Body1';
import Stat from '@components/ra/typography/Stat';
import { antlerStyle } from '@constants/styles';
import christy from '@public/enhanced/christy-blur.png';
import lance from '@public/enhanced/lance.jpg';
import mikeJulie from '@public/enhanced/mike-julie-blur.png';
import sheryl from '@public/enhanced/sheryl.jpg';
import tim from '@public/enhanced/tim.jpg';
import brian from '@public/enhanced/brian.jpg';
import christineDavid from '@public/enhanced/christine-david.jpg';

const clientData = [
  {
    name: 'Mike & Julie',
    quote:
      'Laurie was an expert at answering our questions, and getting back to us quickly to help with any issues. The confidence we have in her and Fair Square Medicare made it easy for us to renew with confidence.',
    image: mikeJulie,
    bulletPoints: [
      "Why we selected Mike's Medicare Supplement",
      'Lori needs half year coverage in Florida.',
      "Lori's local pharmacy is in network.",
      "All Lori's doctors are covered in this plan.",
    ],
    year: 2023,
  },
  {
    name: 'Sheryl',
    quote:
      "Fair Square honestly wants to help you choose the best option, both health-wise and financially. I couldn't believe signing up for Medicare could be this painless. They are light years ahead of anywhere else you look or call - and no expense to you.",
    image: sheryl,
    bulletPoints: [],
    year: 2023,
  },
  {
    name: 'Lance',
    quote:
      'Whenever I am asked, I quickly pass on the name of Fair Square to my friends and family as they approach the time in life when Medicare becomes part of their healthcare needs.',
    image: lance,
    bulletPoints: [],
    year: 2023,
  },
  {
    name: 'Christy',
    quote:
      "I'm signed up for an Advantage plan and am thrilled with the benefits I now have for no extra cost! Imagine that! I look forward to visiting with Steve again next year to reevaluate my plan. Thank you Fair Square!",
    image: christy,
    bulletPoints: [
      "Why we selected Christy's Medicare Advantage",
      'Nate needs half year coverage in Florida.',
      "Nate's local pharmacy is in network.",
      "All Nate's doctors are covered in this plan.",
    ],
    year: 2023,
  },
  {
    name: 'Christine & David',
    quote:
      'Joe took the daunting task off our shoulders and we felt confident with our plan. We can honestly say that was because of Joe’s expert communication skills and the time and care that he took with us.',
    image: christineDavid,
    bulletPoints: [],
    year: 2023,
  },
  {
    name: 'Brian',
    quote:
      'I was given several options to consider and was not pushed in anyway by my agent Steve. He gave me a couple of weeks to discuss with my wife and do any other research on my own as well. In the end, we choose the policy that made the most sense for me and our budget.',
    image: brian,
    bulletPoints: [],
    year: 2023,
  },
  {
    name: 'Tim',
    quote:
      'Laurie was a wonderful resource, patiently explaining the process and offerings in terms I could understand. I could not have been more pleased with the insights Laurie provided.',
    image: tim,
    bulletPoints: [],
    year: 2023,
  },
];

const BaseClients = ({ renderHeader, renderDescription }) => (
  <Stack py={['72px', '72px', '72px', 160]} bg="tan" px={antlerStyle.px} spacing={0} alignItems="center">
    <Box w="100%" maxW="1400px">
      <Flex alignItems="center" flexDirection={['column', 'column', 'column', 'row']} gap={[8, 8, 8, 0]}>
        <Stack flex={3} spacing={8}>
          {renderHeader()}

          <Box sx={{ ' *': { display: 'inline' } }}>{renderDescription()}</Box>
        </Stack>
        <Flex flex={2} justifyContent="center" w="100%">
          <Stack w={['100%', '100%', '216px']} spacing={-4}>
            <Stat whiteSpace="nowrap">94%</Stat>
            <Body1>of clients stay with us year after year.</Body1>
          </Stack>
        </Flex>
      </Flex>
      <QuoteSlider
        agentVariant={false}
        eyebrow="Every recommendation considers all of our client's needs."
        quotes={clientData}
        quoteHeight={['352px', '415px', '290px', '310px']}
        renderSelectedImage={({ imageSrc, alt, bulletPoints }) => (
          <AspectRatio
            flex={1}
            minW={[300, 300, 530]}
            w={[300, 300, 530]}
            h={[300, 300, 530]}
            justifyContent="center"
            position="relative"
          >
            <MainPhoto imageSrc={imageSrc} clientFirstName={alt} bulletPoints={bulletPoints} />
          </AspectRatio>
        )}
      />
    </Box>
  </Stack>
);

export default BaseClients;
