import { Heading } from '@chakra-ui/react';

const Quote = ({ children, ...rest }) => {
  return (
    <Heading fontSize={[24, 24, 32]} {...rest}>
      {children}
    </Heading>
  );
};

export default Quote;
